import { useRef, useState } from "react";
import { useOnHoverOutside } from "../../hooks/useOnHoverOutside";
import { MainMenuType, SubMenuItemType } from "../MainState";
//=============================================================================================
interface MySubMenuProps{
    subMenu:SubMenuItemType,
}
//---------------------------------------------------------------------------------------------
export  function MySubMenu({subMenu}:MySubMenuProps) {
    const dropdownRef = useRef(null);
    const [dropDownMenuOpen, setDropDownMenuOpen] = useState(false);
    const closeHoverMenu = () => {
        setDropDownMenuOpen(false);
    };
    useOnHoverOutside(dropdownRef, closeHoverMenu);
    const _style = dropDownMenuOpen?{color:'#fce300'}:{color:'white'};
    return(
        <li className="nav-item"
        
        ref={dropdownRef}
            style={{margin:5}}   
            onMouseOver={() =>{ 
                setDropDownMenuOpen(true)
            }}
        >
                 {/* <a className="nav-link" aria-current="page" href="#">{subMenu.nameen}</a> */}
        {/* <div 
        
            ref={dropdownRef}
            style={{margin:5}}   
            onMouseOver={() =>{ 
                setDropDownMenuOpen(true)
            }} 
        > */}

            <span 
                style={{margin:5,cursor:'pointer',..._style}}
            >
                {subMenu.nameen}
            </span>
        {/* </div> */}
        </li>
    )
}
//=============================================================================================
interface MyMainMenuProps{
    menu:MainMenuType,
}
//---------------------------------------------------------------------------------------------
export  function MyMainMenu({menu}:MyMainMenuProps) {
    const dropdownRef = useRef(null);
    const [dropDownMenuOpen, setDropDownMenuOpen] = useState(false);
    const closeHoverMenu = () => {
        setDropDownMenuOpen(false);
    };
    useOnHoverOutside(dropdownRef, closeHoverMenu);

    let _style=dropDownMenuOpen?{color:'#fce300'}:{color:'white'}
    
    return (
        <div
            ref={dropdownRef}
        >
            <div
                style={{margin:5,cursor:'pointer',..._style}}
                onMouseOver={() => setDropDownMenuOpen(true)}             
            >
              
                    <span>{menu.nameen}</span>
                {dropDownMenuOpen&&<div style={{height:2,background:'#fce300'}}></div>}
                
                {dropDownMenuOpen&&(menu.subMenus.length>0)&&
                <div 
                    style={{
                        position:'absolute',
                        zIndex:100,
                        display:'flex',
                        flexDirection:'column',
                    }}
                >
                    
                    <div
                        style={{
                            height:5
                        }}
                    />
                    <div
                        style={{
                        display:'flex',
                        flexDirection:'column',
                        background:'#53565a',
                        }} 
                    >
                        {menu.subMenus.map(s=><MySubMenu key ={s.nameen} subMenu={s}/>)}
                    </div>
                </div>
                }
            </div>
        </div>
    )
}
//=============================================================================================