import { MainMenuType } from "./MainState";
import {MyMainMenu} from "./Menus/MainMenu";
import logo from '../assets/logo.png';

import { Navbar, Nav, Container } from 'react-bootstrap';
//=================================================================================================================
interface MyToolBarProps{
    mainMenus:MainMenuType[],
}
//-----------------------------------------------------------------------------------------------------------------
export function MyToolBar({mainMenus}:MyToolBarProps){
    return(
        <>
        <Navbar collapseOnSelect fixed='top' expand='sm' variant="light" 
            style={{background:'#34495E'}}
        >
        <Container>
        <img src={logo} style={{marginRight:25,marginLeft:25,height:80}}/>
            <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
            <Navbar.Collapse id='responsive-navbar-nav'>
            
            <Nav className="me-auto">
                {mainMenus.map(m=>
                    <MyMainMenu
                        key={m.nameen}
                        menu={m} 
                    />
                )}
            {/* <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#features">Features</Nav.Link>
            <Nav.Link href="#pricing">Pricing</Nav.Link> */}
          </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      </>
       
    )
}
//=================================================================================================================

