export type SubMenuItemType ={
    nameen:string,
}
export type MainMenuType={
    nameen:string,
    subMenus:SubMenuItemType[],
}
export type MainStateType={
    mainMenus:MainMenuType[],
}

export const mainState:MainStateType =
{
    mainMenus: [
        {
            nameen:'Consumer',
            subMenus:[
                {nameen:'Consumer Products'},
                {nameen:'Consumer Articles'},
            ]
        },
        {
            nameen:'Commercial',
            subMenus:[
                {nameen:'Commercial Products'},
                {nameen:'Commercial Articles'},
            ]
        },
        {
            nameen:'Industrial',
            subMenus:[
                {nameen:'Industrial Products'},
                {nameen:'Industrial Articles'},
                {nameen:'Industrial Resources'},
            ]
        }
    ]
}