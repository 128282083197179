import { MyHorizontalMenu } from "./MyHorizontalMenu";
import { MySlider } from "./MySlider";

interface MainPageProps{

}
export function MainPage({}:MainPageProps){
    return(
        <div
            style={{
                padding:2

            }}
        >
            <div style={{height:95}}></div>
            <MySlider/>
            <MyHorizontalMenu/>
        </div>
    )
}