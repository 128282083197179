import React from 'react';
import logo from './logo.svg';
import './App.css';
import { MyToolBar } from './components/toolbar';
import { MainPage } from './components/MainPage';
import { mainState } from './components/MainState';

function App() {
  return (
    <div
      style={{
        margin:2
      }}
    >
      <MyToolBar mainMenus={mainState.mainMenus}/>
      <MainPage/>
    </div>
  );
}

export default App;
