import { log } from 'console';
import { useRef, useState } from 'react';
import CommercialImg from '../assets/CommercialImg.jpeg';
import ConsumerImg from '../assets/ConsumerImg.jpeg';
import IndustrialImg from '../assets/IndustrialImg.jpeg';
import { useOnHoverOutside } from '../hooks/useOnHoverOutside';

//====================================================================================================================
interface MyZoomEffectImageProps{
    ImgSrc:any,
}
//--------------------------------------------------------------------------------------------------------------------
export function MyZoomEffectImage({ImgSrc}:MyZoomEffectImageProps){
    const w:number = (window.innerWidth<768)?window.innerWidth:(window.innerWidth-65)/3;

    const hoverRefRef = useRef(null);
    const [isHover, setHover] = useState(false);
    const unHover = () => {
        setHover(false);
    };
    useOnHoverOutside(hoverRefRef, unHover);
    const _style = isHover?{scale:'1.05',transform:'transform 200ms linear'}:{};
    return(
        <div 
            style={{width:w,height:w,overflow:'hidden'}}
            onMouseOver={()=>setHover(true)}
            ref={hoverRefRef}
        >
            <img  src ={ImgSrc} width={w}  style={{cursor:'pointer',objectFit:'contain',..._style}} />
        </div>
    )
}
//====================================================================================================================
interface MyHorizontalMenuProps{

    
}
//--------------------------------------------------------------------------------------------------------------------
export function MyHorizontalMenu({}:MyHorizontalMenuProps){
    // const w:number = (window.innerWidth-(20*(3-1)))/3;
    // const _style = (window.innerWidth<768)?{flexWrap:'wrap'}:{gap:20};
   if (window.innerWidth<768)
    return(
        <div
            // style={{}}
            // style={{flexDirection:'column'}}
        >
            <MyZoomEffectImage ImgSrc={CommercialImg}/>
            <MyZoomEffectImage ImgSrc={ConsumerImg}/>
            <MyZoomEffectImage ImgSrc={IndustrialImg}/>
        </div>
    )
    else
    return(
        <div
            style={{
                display:'flex',
                flexDirection:'row',
                justifyContent:'space-between'
                // flexDirection:'column'
            }}
        >
            <MyZoomEffectImage ImgSrc={CommercialImg}/>
            <MyZoomEffectImage ImgSrc={ConsumerImg}/>
            <MyZoomEffectImage ImgSrc={IndustrialImg}/>
        </div>
    )

    
}
