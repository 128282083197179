import Carousel from 'react-bootstrap/Carousel';
import consumerImg from '../assets/ConsumerBanner.jpeg';
import commercialImg from '../assets/CommercialBanner.jpeg';
import IndustrialImg from '../assets/IndustrialBanner.jpeg';
//====================================================================================
interface MySliderProps{}
//------------------------------------------------------------------------------------
export function MySlider({}:MySliderProps){
    return(
        <Carousel
            className=" mx-auto"
        >
             <Carousel.Item>
               
                <img
                    className="d-block mx-auto"
                    style={{objectFit:'contain',width:'100%'}}
                    src={consumerImg}
                    alt="consumer"
                />
            </Carousel.Item>

            <Carousel.Item>
                <img
                    className="d-block mx-auto"
                    style={{objectFit:'contain',width:'100%'}}
                    src={commercialImg}
                    alt="consumer"
                />
              
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block mx-auto"
                    style={{objectFit:'contain',width:'100%'}}
                    src={IndustrialImg}
                    alt="consumer"
                />
              
            </Carousel.Item>
        </Carousel>
    )
}
//====================================================================================